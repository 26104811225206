.detailsContainer {
  margin-top: 17vw;
}

@media only screen and (max-width: 600px) {
  .detailsContainer {
    margin-top: 0;
  }

  .whatSection {
    justify-content: center;
    h2 {
      font-size: 11vw;
    }
  }
}
