.whoWeAre {
  padding: 1 5vw;
  width: calc(100% - 10vw);

  h2 {
    margin-bottom: 4vw;
    text-align: right;
  }

  p {
    font-size: 2vw;
    color: #707070;
    text-align: right;
  }

  a {
    display: inline-block;
    text-decoration: none;
    background-color: #fe1995;
    color: white;
    font-weight: bold;
    padding: 0.7vw 2vw;
    border-radius: 10px;
    margin: 2vw 0 0 17vw;
    font-size: 1.7vw;
  }
}

@media only screen and (max-width: 600px) {
  .whoWeAre {
    justify-content: center;

    h2 {
      font-size: 11vw;
    }
    p {
      font-size: 5vw;
    }
    a {
      font-size: 6vw;
      padding: 2vw 5vw;
    }
  }
}
