.ourSection {
  position: relative;
  flex-direction: row;
  align-items: center;
  h2 {
    font-size: 5vw;
  }

  p {
    font-size: 2vw;
    width: 40vw;
    margin-bottom: 10vw;
  }

  & > div {
    position: relative;

    &::before {
      background-image: url(../../assets/arrow.svg);
      background-repeat: no-repeat;
      background-size: contain;
      border-radius: 50%;
      height: 3vw;
      width: 3vw;
      display: block;
      position: absolute;
      right: 0;
      top: 0;
    }
  }

  .circle {
    margin: 0;
    border-radius: 50%;
    height: 14vw;
    width: 14vw;
    position: absolute;
    transition: top 1s ease 0s, left 1s ease 0s, bottom 1s ease 0s,
      right 1s ease 0s;

    &.topLeft {
      top: -8vw;
      left: 8vw;

      &.vision {
        top: 5vw;
        left: 13vw;
      }
    }

    &.bottom {
      bottom: -9vw;
      left: 43vw;

      &.vision {
        bottom: 0vw;
        left: 43vw;
      }
    }

    &.right {
      right: -9.5vw;
      top: 50vh;

      &.vision {
        right: 1vw;
        top: 50vh;
      }
    }
  }

  .rarityR {
    background-image: url(../../assets/rarity_r_green.svg);
    background-repeat: no-repeat;
    background-size: contain;
    height: 8vw;
    width: 8vw;
    position: absolute;
    margin-top: 0;

    &.topRight {
      top: 5vw;
      left: 83vw;
    }

    &.bottomLeft {
      bottom: 5vw;
      left: 6vw;
    }
  }

  .arrow {
    background-image: url(../../assets/arrow.svg);
    background-repeat: no-repeat;
    background-size: contain;
    border-radius: 50%;
    height: 3vw;
    width: 3vw;
  }

  .next,
  .prev {
    position: absolute;
    top: 17vw;

    &:hover,
    &:focus {
      cursor: pointer;
    }
  }
  .next {
    right: -6vw;
  }
  .prev {
    left: -6vw;
    transform: rotate(180deg);
  }
}

@media only screen and (max-width: 600px) {
  .ourSection {
    align-items: center;

    h2 {
      font-size: 11vw;
      margin-bottom: 4vw;
    }
    p {
      font-size: 4vw;
      display: inline-block;
      text-align: center;
      width: 65vw;
    }
    .arrow {
      height: 7vw;
      width: 7vw;
      top: 24vw;
    }
    .next {
      right: -10vw;
    }
    .prev {
      left: -10vw;
    }
  }
}
