details {
  text-align: left;
  margin: 0 4vw;
  border-bottom: 1px solid white;
  overflow: hidden;
  transition: max-height 0.5s ease-in-out;
  max-height: 9vw;
}

details[open] {
  max-height: 80vh;
}

details.closed {
  max-height: 8vh;
}

details:first-child {
  border-top: 1px solid white;
}

details > .detailsContent {
  padding-left: 5vw;
}

summary {
  position: relative;
  cursor: pointer;
  list-style: none;
  font-size: 2.8vw;
  font-weight: bold;
  padding: 1.6vw 0;
  display: flex;
  align-items: center;
  justify-content: space-between;

  svg {
    height: 4.5vw;
    width: 4.5vw;
  }
}

@media only screen and (max-width: 600px) {
  details {
    max-height: 16vw;
  }
  summary svg {
    height: 7vw;
    width: 7vw;
  }
}
