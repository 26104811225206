.rarityR {
  position: absolute;
  top: 0;
  left: 0;
  width: 4vw;
  height: 4vw;
  margin: 2vw 0 0 2vw;
  background-image: url(../../assets/rarity_r_white.svg);
  background-repeat: no-repeat;
  background-size: contain;
}

.icon {
  display: inline-block;
  height: 7vw;
  width: 7vw;
  margin: 0 0.9vw;
  background-repeat: no-repeat;
  background-size: cover;
  animation: bgfade 4s infinite;
}

.container {
  flex-wrap: wrap;
}

@keyframes bgfade {
  0% {
    background-image: url("../../assets/1.png");
  }
  24% {
    background-image: url("../../assets/1.png");
  }
  25% {
    background-image: url("../../assets/2.png");
  }
  49% {
    background-image: url("../../assets/2.png");
  }
  50% {
    background-image: url("../../assets/3.png");
  }
  74% {
    background-image: url("../../assets/3.png");
  }
  75% {
    background-image: url("../../assets/4.png");
  }
  100% {
    background-image: url("../../assets/4.png");
  }
}

@media only screen and (max-width: 600px) {
  .weAre {
    display: block;
    width: 100%;
    margin-bottom: 4vw;
    font-size: 10vw;
  }
  .container {
    font-size: 12vw;
  }
  .icon {
    height: 9vw;
    width: 9vw;
  }
}
