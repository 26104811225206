.input:not([type="submit"]) {
  background-color: black;
  border-top: none;
  border-left: none;
  border-right: none;
  font-size: 2.1vw;
  color: #fe1995;
  caret-color: white;
  margin-left: 1.2vw;

  &:focus {
    outline: none;
    border-color: #fe1995;
  }

  &::placeholder {
    color: #fe199530;
  }
}

.input[type="submit"] {
  color: white;
  font-size: 2.1vw;
  background-color: #fe1995;
  display: block;
  margin: 5vw auto 0;
  padding: 1vw 4vw;
  border-radius: 1.5vw;
  border: none;
  cursor: pointer;
  &:hover,
  &:focus {
    background-color: darken($color: #fe1995, $amount: 15%);
  }
}

.label {
  font-size: 2vw;
  font-weight: 600;
}

@media only screen and (max-width: 600px) {
  .contactForm h2 {
    font-size: 9.5vw;
    margin-left: 1.5vw;
    margin-right: 1.5vw;
  }

  .input {
    font-size: 4vw;
    &:not([type="submit"]) {
      margin-top: 5vw;
      font-size: 4vw;
    }
    &[type="submit"] {
      margin-top: 10vw;
      font-size: 5.5vw;
      padding: 2vw 6vw;
    }
  }

  .label {
    font-size: 3.7vw;
  }
}
