.underlined {
  text-underline-offset: 0.4vw;
}

.slidetrack {
  margin: 6vw 0 10vw;
  animation: scroll 8s ease-in-out infinite;
  display: flex;
  width: 700vw;

  .container {
    width: 100vw;
    display: flex;
    flex-direction: row;
    justify-content: center;
    div {
      height: 9vw;
      width: 9vw;
      background-repeat: no-repeat;
      background-size: contain;
      &.firstlight {
        background-image: url("../../assets/firstlight.jpg");
      }
      &.okx {
        background-image: url("../../assets/okx.jpg");
      }
      &.mclaren {
        background-image: url("../../assets/mclaren.jpg");
        width: 29vw;
      }
      &.mancity {
        background-image: url("../../assets/mancity.jpg");
      }
      &.donkeyblx {
        background-image: url("../../assets/donkeyblx.jpg");
      }
    }
  }
}

.text {
  font-size: 4.5vw;
}

@keyframes scroll {
  0% {
    transform: translateX(300vw);
  }
  4% {
    transform: translateX(300vw);
  }
  16% {
    transform: translateX(200vw);
  }
  24% {
    transform: translateX(200vw);
  }
  36% {
    transform: translateX(100vw);
  }
  44% {
    transform: translateX(100vw);
  }
  56% {
    transform: translateX(0);
  }
  64% {
    transform: translateX(0);
  }
  76% {
    transform: translateX(-100vw);
  }
  84% {
    transform: translateX(-100vw);
  }
  96% {
    transform: translateX(-200vw);
  }
  100% {
    transform: translateX(-200vw);
  }
}

@media only screen and (max-width: 600px) {
  .text {
    font-size: 7vw;
  }
  .slidetrack .container div {
    height: 22vw;
    width: 22vw;
  }
}
