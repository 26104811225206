@font-face {
  font-family: "Gilroy";
  src: url("./fonts/Gilroy-Light.otf") format("opentype");
  font-weight: normal;
}

@font-face {
  font-family: "Gilroy";
  src: url("./fonts/Gilroy-ExtraBold.otf") format("opentype");
  font-weight: bold;
}

.App {
  font-family: "Gilroy";
  text-align: center;
}

p {
  font-size: 1.5vw;
}

.primary {
  color: #fe1995;
}

.bg-primary {
  background-color: #fe1995;
}

.secondary {
  color: #8dfbb4;
}

.bg-secondary {
  background-color: #8dfbb4;
  color: black;
}

.tertiary {
  color: #fdfef2;
}

.bg-tertiary {
  background-color: #fdfef2;
  color: black;
}

.dark {
  color: #323232;
}

.bg-dark {
  background-color: #323232;
  color: #fdfef2;
}

.bg-black {
  background-color: black;
  color: white;
}

.bg-white {
  background-color: white;
  color: black;
}

.full-width {
  width: 100%;
}

.min-full-height {
  padding: 1vw 0;
  min-height: calc(100vh - 2vw);
}

.two-column {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
}

.two-column > * {
  margin: 6vw 0;
}
.two-column.half > * {
  flex: 0 0 50%;
}
.two-column:not(.half) > *:first-child,
.two-column.reverse:not(.half) > *:last-child {
  flex: 0 0 45%;
}
.two-column:not(.half) > *:last-child,
.two-column.reverse:not(.half) > *:first-child {
  flex: 0 0 55%;
}

@media only screen and (max-width: 600px) {
  .two-column {
    flex-direction: column;
    margin: 0 4vw;
  }
  .two-column details {
    margin-right: 0;
  }
  details summary {
    font-size: 4vw;
    padding: 4vw 4vw 4vw 0;
  }
  details p {
    font-size: 3vw;
  }
  details summary::after {
    top: calc(50% - 4vw);
    font-size: 8vw;
    line-height: 8vw;
  }
  .r-image {
    margin: 0;
  }
}

.flex-centered {
  display: flex;
  justify-content: center;
  align-items: center;
}
.flex-column {
  flex-direction: column;
}

.underline {
  text-decoration: underline #fe1995;
  text-underline-offset: 1.4vw;
}

.underline.lower {
  text-underline-offset: 2vw;
}

.r-image {
  background-image: url(./assets/rarity_r_alt.svg);
  background-repeat: no-repeat;
  background-size: contain;
  width: 100%;
}

.overflow-clip {
  overflow: clip;
}

input:-webkit-autofill,
input:-webkit-autofill:focus {
  transition: background-color 600000s 0s, color 600000s 0s;
}

details rect.vertical {
  transform: unset;
  transform-origin: center;
  transition: transform 0.2s ease-out 0s;
}

details[open] rect.vertical {
  transform: rotate(-90deg);
}
